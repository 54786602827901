/* istanbul ignore file */
const bodyParser = (oldBody) => {
  if (typeof oldBody !== 'string') {
    return '';
  }

  const body = oldBody
    .replace(/\n|\r/g, '')
    .replace(/&#8217;/g, "'")
    .replace(/&#8216;/g, "'")
    .replace(/&#8211;/g, '–')
    .replace(/<\/?p[^>]*>/g, '')
    .replace(/<\/?br[^>]*>/g, '');

  return body;
};

module.exports = bodyParser;
